
// mixins.js
import {IBiomarkerResult} from '@/interfaces/biomarkerResults';
import {IPackage} from '@/interfaces/packages';
import {IUser} from '@/interfaces/users';
import {IVisit} from '@/interfaces/visits';
import {Component, Vue} from 'vue-property-decorator';
import {IPatient} from '@/interfaces/patients';
import {
  getPackagesIdsFromPackages,
  getBiomarkerResultsIdsFromBiomarkerResults,
  getVisitsIdsFromVisits,
  getPatientNameOrEmail,
  getPhysicianName,
  convertUtcToLocalTime,
  convertLocalTimeToUtc,
  getFormattedDateTimeFromUtc,
  extractIds,
  getBiomarkerIdsFromBiomarkers,
  getRecommendationIdsFromRecommendations,
  getPatientBiologicalSexFromRequisition,
  getBiomarkerSexDetailsForPatientBiologicalSex,
  checkIfBiomarkerIsCritical,
  getCurrentTimezone,
} from '@/utils/common';
import {dispatchCreateReviewingTime, dispatchGetTimezone, dispatchGetTimezones} from '@/store/crud/actions';
import {listOfStatesShort} from '@/constants/listOptions';
import {getCitiesOfState} from '@/constants/usCities.js';
import {calculateCriticalOutOfRange} from '@/helpers/criticalCalculationsHelper';
import {IBiomarker} from '@/interfaces/biomarkers';
import {IRecommendation} from '@/interfaces/recommendations';
import {IRequisition} from '@/interfaces/requisitions';
import {ITimeTracker, ITimeTrackerCreate} from '@/interfaces/timeTracker';
import {readUserProfile} from '@/store/main/getters';
import moment from 'moment';

@Component
export default class CommonsMixin extends Vue {

  /**
   * This mixin includes common methods that are used in multiple components
   * the utils are mapped from a common util file
   */

  public idleInterval;

  public runIdlerTimer() {
    localStorage.removeItem('timeIdle')
    this.idleInterval = setInterval(() => {
      if (this.$store.state.idleVue.isIdle) {
        const timeIdle = localStorage.getItem('timeIdle');
        const timeIdleParsed = timeIdle ? JSON.parse(timeIdle) : null;
        if (!timeIdleParsed) {
          const idleObj = { timeIdleStart: Date.now().toString() };
          localStorage.setItem('timeIdle', JSON.stringify(idleObj));
        } else {
          timeIdleParsed.timeIdleEnd = Date.now().toString();
          localStorage.setItem('timeIdle', JSON.stringify(timeIdleParsed));
        }
      }
    }, 1000);
  }
  public startTimer() {
    this.runIdlerTimer();
    localStorage.setItem('timeStarted', Date.now().toString());
  }

  public stopTimer() {
    clearInterval(this.idleInterval);
    localStorage.setItem('timeFinish', Date.now().toString());
  }

  public resetTimer() {
    localStorage.removeItem('timeIdle');
    localStorage.removeItem('timeStarted');
    localStorage.removeItem('timeFinish');
    clearInterval(this.idleInterval);
  }


  public async trackTimes(requisitionId: string, reviewType: ITimeTrackerCreate['reviewType']) {
    this.stopTimer();
    const timeStarted = Number(localStorage.getItem('timeStarted'));
    const timeFinish = Number(localStorage.getItem('timeFinish'));
    const timeIdle = JSON.parse(String(localStorage.getItem('timeIdle')));
    const user = readUserProfile(this.$store);

    if (timeFinish && timeStarted && user?.id) {
      const result = await dispatchCreateReviewingTime(this.$store, {
        reviewingPhysicianId: user?.id,
        requisitionId,
        reviewType,
        timeStarted: moment(timeStarted).format('MM-DD-YYYY hh:mm:ss'),
        timeFinish: moment(timeFinish).format('MM-DD-YYYY hh:mm:ss'),
        timeIdle: this.formattedDiffTime(Number(timeIdle.timeIdleStart), Number(timeIdle.timeIdleEnd)),
        timeTotal: this.formattedDiffTime(timeStarted, timeFinish),
      });
      if (result) { this.resetTimer(); }
    }
  }

  public formattedDiffTime(timeStart: number, timeFinish: number) {
    const hours = moment.utc(moment(timeFinish).diff(timeStart)).format('HH');
    const minutes = moment.utc(moment(timeFinish).diff(timeStart)).format('mm');
    const seconds = moment.utc(moment(timeFinish).diff(timeStart)).format('ss');
    return `${hours}:${minutes}:${seconds}`
  }

  /**
   * returns a subArray of packages ids from packages array
   */
  public getPackagesIdsFromPackages(packages: IPackage[]) {
    return getPackagesIdsFromPackages(packages);
  }

  /**
   * Gets a list of biomarkerResults ids from biomarkerResults array
   */
  public getBiomarkerResultsIdsFromBiomarkerResults(biomarkerResults: IBiomarkerResult[]) {
    return getBiomarkerResultsIdsFromBiomarkerResults(biomarkerResults);
  }

  /**
   * reduces the list of biomarkers to a simple list of biomarker ids
   */
  public getBiomarkerIdsFromBiomarkers(biomarkers: IBiomarker[]) {
    return getBiomarkerIdsFromBiomarkers(biomarkers);
  }

  /**
   * reduces a list of recommendations to a simple list of recommendation ids
   */
  public getRecommendationIdsFromRecommendations(recommendations: IRecommendation[]) {
    return getRecommendationIdsFromRecommendations(recommendations);
  }

  /**
   * Rerturns a subArray of visits ids from visits array
   */
  public getVisitsIdsFromVisits(visits: IVisit[]) {
    return getVisitsIdsFromVisits(visits);
  }

  public getPatientNameOrEmail(patient: IPatient) {
    return getPatientNameOrEmail(patient);
  }

  /**
   * Gets a phyisican name from an user object
   */
  public getPhysicianName(physician: IUser) {
    return getPhysicianName(physician);
  }

  public getCityFromState(state: string, city: string): boolean {
    let exists = false;

    if (state && state.length > 0) {
      // check if the state exist in the list of states
      if (listOfStatesShort.includes(state)) {
        // get the list of cities from the state
        const citiesFromState = getCitiesOfState(state).reduce((acc, c) => {
          acc.push(c.n);
          return acc;
        }, [] as string[]);

        // finally check if the city exists in the list of cities from the state
        if (citiesFromState.includes(city)) {
          exists = true;
        }
      }
    }
    return exists;
  }

  /*
        Calculates if a result is out of range or not
        by parsing a range string and comparing the result to it

        @param rangeStr: string - the range string
        @param resultStr: string - the result string
    */
  public calculateOutOfRangeResult(rangeStr, resultStr) {
    try {
        return calculateCriticalOutOfRange(rangeStr.toString(), resultStr.toString());
    } catch (error) {
        // if for any reason the calculation fails, or range / result is null / undefined
        // return false
        return false;
    }
  }

  public async getVisitDateTime(visit: IVisit) {
    try {
      if (visit && visit.city && visit.state && visit.visitDate) {
        // before sending the request, check if the city and state exist in the list of cities and states
        if (this.getCityFromState(visit.state, visit.city)) {
          const visitTimezone = await dispatchGetTimezone(this.$store, {
            city: visit.city,
            state: visit.state,
          });
          if (visitTimezone) {
            return Promise.resolve(
              (this.getFormattedDateTimeFromUtc(
                visit.visitDate as string,
                visitTimezone,
              ) as string) || '',
            );
          } else {
            throw new Error('Timezone not found');
          }
        } else {
          throw new Error('City or State not found');
        }
      } else {
        throw new Error('Visit does not have city, state or visit date');
      }
    } catch (error) {
      if (visit.visitDate) {
        // display utc time
        return Promise.resolve(
          (this.getFormattedDateTimeFromUtc(visit.visitDate as string, 'UTC') as string) || '',
        );
      } else {
        return Promise.resolve(visit && visit.visitDate ? visit.visitDate.toString() : '');
      }
    }
  }

  /**
   * gets dateTime for all visits in a list of visits
   * returns a dictionary of key value pairs of all the visits
   * that can be processed (if they have valid state and city)
   * The dictionary format is visitID: timezone
   */
  public async getBatchVisitDateTime(visits: IVisit[]) {
    const formatttedDateTime: any = [];
    const requestDictionary: any = {};
    for (const visit of visits) {
      try {
        if (visit.id && visit && visit.city && visit.state && visit.visitDate) {
          // before sending the request, check if the city and state exist in the list of cities and states
          if (this.getCityFromState(visit.state, visit.city)) {
            requestDictionary[visit.id] = [visit.city, visit.state];
          } else {
            // if city or state is invalid, we will display the utc time
            throw new Error('City or State not found');
          }
        } else {
          // malformed visit
          throw new Error('Visit does not have id, city, state or visit date');
        }
      } catch (error) {
        if (visit.visitDate && visit.id) {
          // display utc time
          formatttedDateTime[visit.id] =
            (this.getFormattedDateTimeFromUtc(visit.visitDate as string, 'UTC') as string) || '';
        } else {
          if (visit.id) {
            formatttedDateTime[visit.id] =
              visit && visit.visitDate ? visit.visitDate.toString() : '';
          } else {
            /* Ignore this visit since it is malformed */
          }
        }
        continue;
      }
    }
    if (requestDictionary && Object.keys(requestDictionary).length > 0) {
      // send the request to get the timezones
      try {
        const visitTimezones = await dispatchGetTimezones(this.$store, requestDictionary);
        if (visitTimezones && Object.keys(visitTimezones).length > 0) {
          // add the timezones to the dictionary
          for (const visitId of Object.keys(visitTimezones)) {
            const visit = visits.find((v) => v.id === visitId);
            formatttedDateTime[visitId] =
              (this.getFormattedDateTimeFromUtc(
                visit?.visitDate as string,
                visitTimezones[visitId],
              ) as string) || '';
          }
        } else {
          throw new Error('Timezones not found');
        }
      } catch (error) {
        // FIXME - important log here
        this.$toast.error('Error getting visit timezones');
      }
    }
    return formatttedDateTime;
  }

  /**
   * Converts utc time to local time am/pm format
   */
  public convertUtcToLocalTime(date: string | Date, timezone: string) {
    return convertUtcToLocalTime(date, timezone);
  }

  /**
   * Converts local time to utc time format (YYYY-MM-DD HH:mm z)
   */
  public convertLocalTimeToUtc(date: string | Date, timezone: string) {
    return convertLocalTimeToUtc(date, timezone);
  }

  /**
   * Convert UTC date to local date
   * in format "dd-mm-yyyy"
   */
  public convertUtcStringToLocalDate(date: string) {
    if (!date) {
      return '';
    }
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();
    return `${month}-${day}-${year}`;
  }

  /**
   * Convert UTC date to local date / time
   * in format "dd-mm-yyyy hh:mm"
   */
  public convertUtcStringToLocalTime(date: string) {
    if (!date) {
      return '';
    }
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();
    const hours = d.getHours();
    const minutes = d.getMinutes();
    return `${month}-${day}-${year} ${hours}:${minutes}`;
  }

  /**
   * Gets a formated dateTime tz string from the UTC dateTime string and a timezone string
   * @param date: string -> server time with format YYYY-MM-DD HH:mm
   * @param timezone: string -> timezone string
   * @returns - formatted dateTime tz string
   */
  public getFormattedDateTimeFromUtc(date: string, timezone: string) {
    return getFormattedDateTimeFromUtc(date, timezone);
  }

  /*
        Util method to reduce and extract ids from the selected objects
    */
  public extractIds(list: any[], key: string = 'id') {
    return extractIds(list, key);
  }

  /**
   * Check if the biomarker is critical for the patient sex
   * @param biomarker - the biomarker object to be checked
   * @param patientSex - the patient sex
   * @returns - true if the biomarker is critical for the patient sex
   */
  public checkIfBiomarkerIsCritical(biomarker: IBiomarker, patientSex: string) {
    return checkIfBiomarkerIsCritical(biomarker, patientSex);
  }

  /**
   * gets the current patient biological sex
   * or returns "unknown" if not found
   */
  public getPatientBiologicalSexFromRequisition(requisition: IRequisition) {
    return getPatientBiologicalSexFromRequisition(requisition);
  }

  /**
   * gets the sexDetails from biomarker
   * based on the patient biological sex.
   * If a matching sexDetails is not found it returns undefined
   */
  public getBiomarkerSexDetailsForPatientBiologicalSex(
    item: IBiomarker,
    patientBiologicalSex: string,
  ) {
    return getBiomarkerSexDetailsForPatientBiologicalSex(item, patientBiologicalSex);
  }

  /**
   * gets the current timezone from the client
   */
  public getCurrentTimezone() {
    return getCurrentTimezone();
  }
}
