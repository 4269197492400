
// mixins.js
import {Component, Vue} from 'vue-property-decorator';
import {STATES} from '@/constants/listOptions';
import {ITimeTracker} from '@/interfaces/timeTracker';

@Component
export default class StatesMixin extends Vue {

  get listOfStates() {
    return STATES;
  }
}
