export enum BiomarkerCriticalPatternType {
    ERROR = -1,
    EQUAL = 0,
    RANGE = 1,
    GREATER_OR_EQUAL_THAN = 2,
    GREATER_THAN = 3,
    LESS_OR_EQUAL_THAN = 4,
    LESS_THAN = 5,
}

export enum CriticalPatternOperator {
  INCLUSIVE = 0,
  EXCLUSIVE = 1,
}

// export const CRITICAL_RANGE_PATTERN_INCLUSIVE = /\[\s*(?:\d*(?:.\d+)?)?,\s*(?:\d*(:?.\d+)?)?\]/gi;
// export const CRITICAL_RANGE_PATTERN_EXCLUSIVE = /\(\s*(?:\d*(?:.\d+)?)?,\s*(?:\d*(:?.\d+)?)?\)/gi;

export const CRITICAL_RANGE_PATTERN_INCLUSIVE = /(\[\s*(?:\d*(?:.\d+)?)?)|(\s*(?:\d*(?:.\d+)?)?\])/i;
export const CRITICAL_RANGE_PATTERN_EXCLUSIVE = /(\(\s*(?:\d*(?:.\d+)?)?)|(\s*(?:\d*(?:.\d+)?)?\))/i;
export const CRITICAL_RANGE_PATTERN = /(?:\(|\[)\s*(?:\d*(?:.\d+)?)?,\s*(?:\d*(?:.\d+)?)?(?:\)|\])/gi;

export const RANGE_REGEX = {
  match: [
    // 10-20
    /\d+-\d+$/i, // 10-20
    // 10 - 20 with any number of spaces in the middle, however there should be at least one space
    // between the numbers and the dash
    /\d+\s+-\s+\d+/i, // 10 - 20
    // >10, <30
    /(?:<|>|≤|≥|>=|<=)+\s?(?:\d.?\d?)+\s?,?\s?(<|>|≤|≥|>=|<=)+(?:\d.?\d?)+/i,
    // special pattern like the two above, however it needs to address for possible negative numbers
    // and it needs to be able to handle the case where there is no space between the numbers and the dash
    // examples: 1. "-10--20" 2. "-2-(+2)", "-5-5", "-5-+5"
    /(\+|-)?\d+\s*-\s*(\+|-|(\(+\+|-\)))?\d+/i, // -10--20, -2-(+2), -5-5, -5-+5
    // between 10 and 20, it will be valid as soon as two numbers are found and between word is found
    /between\s*.*\d+\s+.*\d+/i,
    // from 10 (to|through|and|or) 20, it will be valid as soon as two numbers are found and from word is found
    /\d+\s*.*(to|through|and|or).*\d+/i,
  ],
  exclude: [],
  numberOfNumbers: 2,
};

export const GREATER_OR_EQUAL_THAN_REGEX = {
  match: [
    // greaterorequal|greaterorequalthan|geq|ge|gteq|gte|≥|> = 10
    /(greater\s*.*equal|greaterorequal|greaterorequalthan|min|minimum|geq|ge\s+|gteq|gte|at\s*least|not\s*less\s*than\s|≥|>\s*.*=)\s*.*\d+$/i,
  ],
  exclude: [],
  numberOfNumbers: 1,
};

export const LESS_OR_EQUAL_THAN_REGEX = {
  match: [
    // lessorequal|lessorequalthan|leq|le|lteq|lte|≤|< = 10
    /(less\s*.*equal|lessorequal|lessorequalthan|max|maximum|leq|le\s+|lteq|lte|at\s*most|not\s*greater\s*than\s|≤|<\s*.*=)\s*.*\d+$/i,
  ],
  exclude: [],
  numberOfNumbers: 1,
};

export const LESS_THAN_REGEX = {
  match: [
    // less|lt|<|less|under|below 10
    /less|lt|<|less|under|below|lessthan\s.*\d+$/i,
  ],
  exclude: [/equal|eq|=|greater|gt|>|greater|above|over|greaterthan/i],
  numberOfNumbers: 1,
};

export const GREATER_THAN_REGEX = {
  match: [
    // greater|gt|>|more|above|over 10
    /greater|greaterthan|gt|>|more|above|over\s.*\d+$/i,
  ],
  exclude: [/equal|eq|=|less|lt|<|less|under|below|lessthan/i],
  numberOfNumbers: 1,
};

export const EQUAL_REGEX = {
  match: [
    // equal|eq|= 10
    /equal|eq|=|exactly|precisely|exactly\s.*\d+$/i,
    /\s*\d+\s*$/i, // 10
  ],
  exclude: [/greater|gt|>|more|above|over|greaterthan/i, /less|lt|<|less|under|below|lessthan/i],
  numberOfNumbers: 1,
};

/**
 * This regex capture string should be able to process the following cases:
 * 1. "-25 to +25" // -25 and 25 should be captured
 * 2. "-25-(+25)" // -25 and 25 should be captured
 * 3. "-25-+25" // -25 and 25 should be captured
 * 4. "aasdasdasd 50" 50 should be captured
 * 5. "asdasdasd25" 25 should be captured
 * 6. "-25-(-25)" -25 and -25 should be captured
 * 7. "-25--25" -25 and -25 should be captured
 * 7. "25-25" 25 and 25 should be captured
 * 8. "-7this is a string with a 20, a -15 a 10-10(that one should be positive)
 * and a 10--20(that should be negative) we can even include something
 * like this -5-(+10)" // -7, 20, -15, 10, -10, 10, -20, -5, 10 should be captured
 */
export const CAPTURE_NUMBERS_REGEX = /(?:(?:^\s*\(?)(?<startOfTheString>(?:\+?|-?)\d*\.?\d+)(?:\s?|$))|(?:\s|\(|-)(?<edgeCases>(?:\+|-)\d*\.?\d+)(?:\s*|$)|(?<otherNumbers>\d*\.?\d+)/g;

export const NUMERIC_EXCLUDE_PATTERNS = [
    /^\d+\/\d+$/, // check if the string contains a pattern like 1/2, if this is the case, we want to exclude it
    /^\d+\:\d+$/, // check if the string contains a pattern like 1.2, if this is the case, we want to exclude it
];
