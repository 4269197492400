
// mixins.js
import {
    readBiomarkerResults,
    readBiomarkers,
    readCategories,
    readCauses,
    readExercises,
    readFoods,
    readPackages,
    readPanels,
    readPatients,
    readRecommendations,
    readRequisitionDocuments,
    readRequisitions,
    readSelfCares,
    readSupplements,
    readSymptoms,
    readUsers,
    readVisits,
} from '@/store/crud/getters';

import { Component, Watch } from 'vue-property-decorator';
import {
    userMapConfig,
    biomarkersMapConfig,
    biomarkersResultsMapConfig,
    panelsMapConfig,
    visitsMapConfig,
    packagesMapConfig,
    requisitionsMapConfig,
    patientMapConfig,
    exercisesMapConfig,
    recommendationsMapConfig,
    foodsMapConfig,
    supplementsMapConfig,
    symptomsMapConfig,
    causesMapConfig,
    categoriesMapConfig,
    selfCaresMapConfig,
} from '@/constants/mapConfigs';

import DataDigester from './DataDigester.vue';
import { IUser, IUserProfileModel } from '@/interfaces/users';
import { IVisit } from '@/interfaces/visits';
import { IListItem } from '@/interfaces/listItem';
import { IBiomarker } from '@/interfaces/biomarkers';
import { IPanel } from '@/interfaces/panels';
import { IPatient } from '@/interfaces/patients';
import { IPackage } from '@/interfaces/packages';
import { IBiomarkerResult } from '@/interfaces/biomarkerResults';
import { IRequisition } from '@/interfaces/requisitions';
import { ITableRequisitionDocuments } from '@/interfaces/documents';
import { IExercise } from '@/interfaces/exercises';
import { IRecommendation } from '@/interfaces/recommendations';
import { ISupplement } from '@/interfaces/supplements';
import { IFood } from '@/interfaces/foods';
import { ISymptom } from '@/interfaces/symptoms';
import { ICause } from '@/interfaces/causes';
import { ICategory } from '@/interfaces/categories';
import { ISelfCare } from '@/interfaces/selfCare';

@Component
export default class DataObserver extends DataDigester {
    public localBiomarkersList: IListItem[] = [];
    public localUsersList: IListItem[] = [];
    public localPhysiciansList: IListItem[] = [];
    public localPanelsList: IListItem[] = [];
    public localPatientsList: IListItem[] = [];
    public localPackagesList: IListItem[] = [];
    public localVisitsList: IListItem[] = [];
    public localBiomarkerResultsList: IListItem[] = [];
    public localRequisitionsList: IListItem[] = [];
    public localExercisesList: IListItem[] = [];
    public localRecommendaionsList: IListItem[] = [];
    public localFoodsList: IListItem[] = [];
    public localSupplementsList: IListItem[] = [];
    public localSymptomsList: IListItem[] = [];
    public localCausesList: IListItem[] = [];
    public localCategoriesList: IListItem[] = [];
    public localSelfCareList: IListItem[] = [];
    public dataChanged: any = {
        biomarkers: false,
        users: false,
        physicians: false,
        panels: false,
        patients: false,
        packages: false,
        visits: false,
        biomarkerResults: false,
        requisitions: false,
        exercises: false,
        recommendations: false,
        foods: false,
        supplements: false,
        symptoms: false,
        causes: false,
        categories: false,
        selfCares: false,
    };

    /**
     * maps visits from the store to the component
     */
    get visits() {
        return readVisits(this.$store) as IVisit [] || [] as IVisit [];
    }

    /**
     * gets existing biomarkers from store
     */
    get biomarkers() {
        return readBiomarkers(this.$store) as IBiomarker [] || [] as IBiomarker [];
    }

    /**
     * maps users from the store to the component
     */
    get users() {
        return readUsers(this.$store) as IUser [] || [] as IUser [];
    }

    /**
     * maps panels from store into the component
     */
    public get panels() {
        return readPanels(this.$store) as IPanel [] || [] as IPanel [];
    }

    /**
     * map patients from store into the component
     */
    get patients() {
        return readPatients(this.$store) as IPatient [] || [] as IPatient [];
    }

    /**
     * Maps Packages from store into the compoenent
     */
    get packages() {
        return readPackages(this.$store) as IPackage [] || [] as IPackage [];
    }

    /**
     * maps biomarkers from the store to the component
     */
    get biomarkerResults() {
        return readBiomarkerResults(this.$store) as IBiomarkerResult [] || [] as IBiomarkerResult [];
    }

    get requisitions() {
        return readRequisitions(this.$store) as IRequisition [] || [] as IRequisition [];
    }

    get requisitionDocuments() {
        return readRequisitionDocuments(this.$store)  || [] as ITableRequisitionDocuments [];
    }

    get exercises() {
        return readExercises(this.$store) || [] as IExercise [];
    }
    get recommendations() {
        return readRecommendations(this.$store) || [] as IRecommendation [];
    }
    get supplements() {
        return readSupplements(this.$store) || [] as ISupplement [];
    }
    get foods() {
        return readFoods(this.$store) || [] as IFood [];
    }
    get symptoms() {
        return readSymptoms(this.$store) || [] as ISymptom [];
    }
    get causes() {
        return readCauses(this.$store) || [] as ICause [];
    }
    get categories() {
        return readCategories(this.$store) || [] as ICategory [];
    }
    get selfCares() {
        return readSelfCares(this.$store) || [] as ISelfCare [];
    }

    get usersList() {
        if (this.dataChanged.users) {
            this.digestData('localUsersList', this.users, userMapConfig, 'id');
            this.dataChanged.users = false;
        }
        return this.localUsersList;
    }

    get physiciansList() {
        if (this.dataChanged.users) {
            this.digestPhysicianData();
            this.dataChanged.physicians = false;
        }
        return this.localPhysiciansList;
    }

    get biomarkersList() {
        if (this.dataChanged.biomarkers) {
            this.digestData('localBiomarkersList', this.biomarkers, biomarkersMapConfig, 'id');
            this.dataChanged.biomarkers = false;
        }
        return this.localBiomarkersList;
    }

    set biomarkersList(value: IListItem[]) {
        this.localBiomarkersList = value;
    }

    get panelsList() {
        if (this.dataChanged.panels) {
            this.digestData('localPanelsList', this.panels, panelsMapConfig, 'id');
            this.dataChanged.panels = false;
        }
        return this.localPanelsList;
    }

    get patientsList() {
        if (this.dataChanged.patients) {
            this.digestData('localPatientsList', this.patients, patientMapConfig, 'id');
            this.dataChanged.patients = false;
        }
        return this.localPatientsList;
    }

    get packagesList() {
        if (this.dataChanged.packages) {
            this.digestData('localPackagesList', this.packages, packagesMapConfig, 'id');
            this.dataChanged.packages = false;
        }
        return this.localPackagesList;
    }

    get visitsList() {
        if (this.dataChanged.visits) {
            this.digestData('localVisitsList', this.visits, visitsMapConfig, 'id');
            this.dataChanged.visits = false;
        }
        return this.localVisitsList;
    }

    get biomarkerResultsList() {
        if (this.dataChanged.biomarkerResults) {
            this.digestData('localBiomarkerResultsList', this.biomarkerResults,
                biomarkersResultsMapConfig, 'biomarker_result_id');
            this.dataChanged.biomarkerResults = false;
        }
        return this.localBiomarkerResultsList;
    }

    get requisitionsList() {
        if (this.dataChanged.requisitions) {
            this.digestData('localRequisitionsList', this.requisitions, requisitionsMapConfig, 'id');
            this.dataChanged.requisitions = false;
        }
        return this.localRequisitionsList;
    }

    get exercisesList() {
        if (this.dataChanged.exercises) {
            this.digestData('localExercisesList', this.exercises, exercisesMapConfig, 'id');
            this.dataChanged.exercises = false;
        }
        return this.localExercisesList;
    }
    get recommendationsList() {
        if (this.dataChanged.recommendations) {
            this.digestData('localRecommendaionsList', this.recommendations, recommendationsMapConfig, 'id');
            this.dataChanged.recommendations = false;
        }
        return this.localRecommendaionsList;
    }
    get foodsList() {
        if (this.dataChanged.foods) {
            this.digestData('localFoodsList', this.foods, foodsMapConfig, 'id');
            this.dataChanged.foods = false;
        }
        return this.localFoodsList;
    }
    get supplementsList() {
        if (this.dataChanged.supplements) {
            this.digestData('localSupplementsList', this.supplements, supplementsMapConfig, 'id');
            this.dataChanged.supplements = false;
        }
        return this.localSupplementsList;
    }
    get symptomsList() {
        if (this.dataChanged.symptoms) {
            this.digestData('localSymptomsList', this.symptoms, symptomsMapConfig, 'id');
            this.dataChanged.symptoms = false;
        }
        return this.localSymptomsList;
    }
    get causesList() {
        if (this.dataChanged.causes) {
            this.digestData('localCausesList', this.causes, causesMapConfig, 'id');
            this.dataChanged.causes = false;
        }
        return this.localCausesList;
    }
    get categoriesList() {
        if (this.dataChanged.categories) {
            this.digestData('localCategoriesList', this.categories, categoriesMapConfig, 'id');
            this.dataChanged.categories = false;
        }
        return this.localCategoriesList;
    }

    get selfCaresList() {
        if (this.dataChanged.selfCares) {
            this.digestData('localSelfCareList', this.selfCares, selfCaresMapConfig, 'id');
            this.dataChanged.selfCares = false;
        }
        return this.localSelfCareList;
    }

    /**
     * watches for user changes and digest
     * the data to generate a list of physicians
     */
    @Watch('users', { immediate: true })
    public onUsersChange() {
        this.dataChanged.users = true;
        this.dataChanged.physicians = true;
    }

    /**
     * watches for biomarkers changes and digest
     * the data to generate a list of biomarkers names
     */
    @Watch('biomarkers', { immediate: true })
    public onBiomarkersChange() {
        this.dataChanged.biomarkers = true;
    }

    /**
     * watches for biomarker results changes and digest
     * the data to generate a list of biomarkers results names
     */
    @Watch('biomarkerResults', { immediate: true })
    public onBiomarkersResultChanges() {
        this.dataChanged.biomarkerResults = true;
    }

    @Watch('panels', { immediate: true })
    public digestPanelData() {
        this.dataChanged.panels = true;
    }

    @Watch('visits', { immediate: true })
    public digestVisitList() {
        this.dataChanged.visits = true;
    }

    @Watch('requisitions', { immediate: true })
    public digestRequisitionsList() {
        this.dataChanged.requisitions = true;
    }

    /**
     * watches for patients changes and digest
     * the data to generate a list of patients names
     */
    @Watch('patients', { immediate: true })
    public onPatientsChange() {
        this.dataChanged.patients = true;
    }

    /**
     * watches for packages changes and digest
     * the data to generate a list of packages names
     */
    @Watch('packages', { immediate: true })
        public onPackagesChange() {
        this.dataChanged.packages = true;
    }

    /**
     * watches for exercises changes and digest
     * the data to generate a list of exercises names
     */
    @Watch('exercises', { immediate: true })
    public onExercisesChange() {
        this.dataChanged.exercises = true;
    }
    /**
     * watches for recommendations changes and digest
     * the data to generate a list of recommendations names
     */
    @Watch('recommendations', { immediate: true })
    public onRecommendationsChange() {
        this.dataChanged.recommendations = true;
    }
    /**
     * watches for foods changes and digest
     * the data to generate a list of foods names
     */
    @Watch('foods', { immediate: true })
    public onFoodsChange() {
        this.dataChanged.foods = true;
    }
    /**
     * watches for supplements changes and digest
     * the data to generate a list of supplements names
     */
    @Watch('supplements', { immediate: true })
    public onSupplementsChange() {
        this.dataChanged.supplements = true;
    }
    /**
     * watches for symptoms changes and digest
     * the data to generate a list of symptoms names
     */
    @Watch('symptoms', { immediate: true })
    public onSymptomsChange() {
        this.dataChanged.symptoms = true;
    }

    /**
     * watches for causes changes and digest
     * the data to generate a list of causes names
     */
    @Watch('causes', { immediate: true })
    public onCausesChange() {
        this.dataChanged.causes = true;
    }

    /**
     * watches for categories changes and digest
     * the data to generate a list of categories names
     */
    @Watch('categories', { immediate: true })
    public onCategoriesChange() {
        this.dataChanged.categories = true;
    }

    /**
     * watchs for self care changes and digest
     * the data to generate a list of self care names
     */
    @Watch('selfCares', { immediate: true })
    public onSelfCareChange() {
        this.dataChanged.selfCares = true;
    }

    /**
     * get a user name + last name by id.
     * If it does not have a last name, it will return the first name
     * If it does not have a first name, it will return the email
     */
    public getUserNameById(id: string) {
        const userFound = this.users.find((user) => user.id === id);

        if (userFound) {
            if (userFound.fname && userFound.lname) {
                return `${userFound.fname} ${userFound.lname}`;
            } else {
                return userFound.fname || userFound.email;
            }
        } else {
            return '';
        }
    }

    /**
     * digests the user data to generate a list of physicians
     */
    private digestPhysicianData() {
        // first we create a copy of the list
        let tempPhysicians = [...this.users];
        if (tempPhysicians && tempPhysicians.length > 0) {
            // we filter the tempPhysicians object to get only the physicians
            tempPhysicians = tempPhysicians.filter((user: IUserProfileModel) => {
            return user.isPhysician && user.isActive;
            });
            // now we map the physicians to a list of objects with text and value
            this.digestData('localPhysiciansList', tempPhysicians, userMapConfig, 'id');
        }
    }
}
