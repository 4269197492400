
// mixins.js
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FormUtilsMixin extends Vue {

public hasValue = (val: any) => {
    if (val === undefined || val === null) {
        return false;
    } else {
        if (typeof val === 'string') {
            return val.length > 0;
        } else if (typeof val === 'number') {
            return val >= 0;
        } else if (Array.isArray(val)) {
            if (val.length === 0) {
                return false;
            } else {
                if (val.length === 1) {
                    return this.hasValue(val[0]);
                } else {
                    return true;
                }
            }
        } else if (typeof val === 'object') {
            return Object.keys(val).length > 0;
        } else {
            return true;
        }
    }
}

public isEmpty = (val: any) => {
    return !this.hasValue(val);
}

public isValidNumber = (value: string) => {
    return !this.isEmpty(value) && !isNaN(parseInt(value, 10));
}

public isValidFloat = (value: string) => {
    return !this.isEmpty(value) && !isNaN(parseFloat(value));
}

public isListDifferent = (list1: any[], list2: any[]) => {
    if (list1 && list2 && Array.isArray(list1) && Array.isArray(list2)) {
        if (list1.length !== list2.length) {
            return true;
        } else {
            for (let i = 0; i < list1.length; i++) {
                if (list1[i] !== list2[i]) {
                    return true;
                }
            }
        }
    } else {
        if (list1 !== list2) {
            return true;
        }
    }
    return false;
}

public setFieldIfChanges = (target: object, key: string,  newValue: any, oldValue: any) => {
    if (this.objectChanged(newValue, oldValue)) {
        target[key] = newValue;
    }
}

public setFloatValueFromStringIfChanges = (target: object, key: string,  newValue: string, oldValue: string) => {
    if (oldValue && oldValue.length > 0) {
        if (newValue && newValue.length > 0) {
            if (this.isValidFloat(newValue) && parseFloat(newValue) !== parseFloat(oldValue)) {
                target[key] = parseFloat(newValue);
            }
        } else {
            target[key] = 0;
        }
    } else if (newValue && newValue.length > 0) {
        if (this.isValidFloat(newValue)) {
            target[key] = parseFloat(newValue);
        }
    }
}

public setFieldIfExistAndValueChanged = (target: object, key: string,  newValue: any, oldValue: any) => {
    let changed = false;
    if (this.hasValue(newValue) && newValue !== oldValue) {
        target[key] = newValue;
        changed = true;
    }
    return changed;
}

/**
 * Clone deeply a list of objects.
 * This is the prefered way to clone a list
 * since a shallow copy will not work for Vue, inner references will be the same, resulting in
 * unexpected behaviour.
 */
public cloneList = (list: any[]) => {
    if (list && list.length > 0) {
        return JSON.parse(JSON.stringify(list));
    } else {
        return [];
    }
}

/**
 * @deprecated
 * This method is not reliable, use setFieldIfChanges instead.
 * The difference is that the function objectChanged is used to compare the objects, this one will
 * transform the objects to JSON and compare the strings.
 */
public setFieldIfListChanged = (target: object, key: string,  newValue: any = [], oldValue: any = []) => {
    if (newValue && newValue.length !== (oldValue ? oldValue.length : [])) {
        target[key] = newValue;
    } else {
        // check by content
        if (newValue && oldValue) {
            for (let i = 0; i < newValue.length; i++) {
                if (newValue[i] !== oldValue[i]) {
                    target[key] = newValue;
                    break;
                }
            }
        }
    }
}

/**
 * Compare two objects and return true if they are different.
 * The comparison is done by transforming the objects to JSON and comparing the strings.
 * The only way this could fail is if the keys are sorted differently.
 * TODO - Is there a better way to compare objects? maybe using lodash?
 * @param obj1 - The first object to compare
 * @param obj2 - The second object to compare
 */
public objectChanged = (obj1: any, obj2: any) => {
    if (obj1 && obj2) {
        const obj1Json = JSON.stringify(obj1);
        const obj2Json = JSON.stringify(obj2);
        return obj1Json !== obj2Json;
    } else if (obj1 && !obj2) {
        return true;
    } else if (!obj1 && obj2) {
        return true;
    } else {
        return false;
    }
}

}
