
// mixins.js
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DataDigester extends Vue {

  /**
   * Digest existing data into a text / value format
   * @param objectName - the name of the object that will be set as result
   * @param data - the data to be digested
   * @param idfield - the name of the field that has the id
   * @param fields - an array of field objects and their separators the format should be
   * [{name: 'fieldName', separator: ',', prefix: ''}, {field: 'fieldName', separator: ';'}]
   */
  public digestData(objectName, data, fields = [{prefix: '', key: 'name', separator: ''}], idField = 'id') {
    if (data) {
        const mappedData = data.map((item: any) => {
            const text = fields.reduce((acc, field) => {
                if (field.prefix) {
                    acc += field.prefix;
                }
                if (field.key) {
                    if (field.key.includes('.')) {
                        try {
                            const keys = field.key.split('.');
                            acc += item[keys[0]][keys[1]] || '';
                        } catch (e) {
                            acc += item[field.key] || '';
                        }
                    } else {
                        acc += item[field.key] || '';
                    }
                }
                if (field.separator) {
                    acc += field.separator;
                }
                return acc;
            }, '');
            const value = item[idField];
            return {text, value};
        });
        Vue.set(this, objectName, mappedData);
    } else {
        Vue.set(this, objectName, []);
    }
  }
}
