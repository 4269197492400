export const userMapConfig = [
    {
        key: 'fname',
        separator: ' ',
        prefix: '',
    },
    {
        key: 'lname',
        separator: '',
        prefix: '',
    },
];

export const patientMapConfig = [
    {
        key: 'fname',
        separator: ' ',
        prefix: '',
    },
    {
        key: 'lname',
        separator: ' ',
        prefix: '',
    },
    {
        key: 'patientContactInfo.email',
        separator: '',
        prefix: '- ',
    },
];

export const biomarkersMapConfig = [
    {
        key: 'name',
        separator: '',
        prefix: '',
    },
];

export const biomarkersResultsMapConfig = [
    {
        key: 'biomarkerName',
        separator: '',
        prefix: '',
    },
];

export const biomarkerResultExpandedMapConfig = [
    {
        key: 'biomarkerName',
        separator: ';',
        prefix: '',
    },
    {
        key: 'testResult',
        separator: '',
        prefix: '',
    },
    {
        key: 'measurementUnits',
        separator: '',
        prefix: ' ',
    },
];

export const panelsMapConfig = [
    {
        key: 'name',
        separator: '',
        prefix: '',
    },
];

export const requisitionsMapConfig = [
    {
        key: 'patient.fname',
        separator: ' ',
        prefix: '',
    },
    {
        key: 'patient.lname',
        separator: '',
        prefix: '',
    },
    {
        key: 'requisitionState',
        separator: '',
        prefix: ' ',
    },
    {
        key: 'referringPhysician.fname',
        separator: ' ',
        prefix: '',
    },
    {
        key: 'referringPhysician.lname',
        separator: '',
        prefix: '',
    },
];

export const visitsMapConfig = [
    {
        key: 'streetAddress',
        separator: ', ',
        prefix: '',
    },
    {
        key: 'city',
        separator: ', ',
        prefix: '',
    },
    {
        key: 'state',
        separator: '',
        prefix: '',
    },
    {
        key: 'visitDate',
        separator: '',
        prefix: ' - ',
    },
];

export const packagesMapConfig = [
    {
        key: 'name',
        separator: '',
        prefix: '',
    },
];

export const exercisesMapConfig = [
    {
        key: 'name',
        separator: '',
        prefix: '',
    },
];

export const supplementsMapConfig = [
    {
        key: 'name',
        separator: '',
        prefix: '',
    },
];

export const foodsMapConfig = [
    {
        key: 'name',
        separator: '',
        prefix: '',
    },
];

export const recommendationsMapConfig = [
    {
        key: 'name',
        separator: ' - ',
        prefix: '',
    },
    {
        key: 'biomarker.questBiomarkerCode',
        separator: ' - ',
        prefix: ' ',
    },
    {
        key: 'outOfRangeType',
        separator: '',
        prefix: ' ',
    },
];

export const causesMapConfig = [
    {
        key: 'name',
        separator: '',
        prefix: '',
    },
];

export const categoriesMapConfig = [
    {
        key: 'categoryName',
        separator: '',
        prefix: '',
    },
];

export const symptomsMapConfig = [
    {
        key: 'name',
        separator: '',
        prefix: '',
    },
];

export const selfCaresMapConfig = [
    {
        key: 'name',
        separator: '',
        prefix: '',
    },
];
