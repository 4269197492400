
// mixins.js

import { Component, Mixins } from 'vue-property-decorator';
import DataObserver from './DataObserver.vue';
import FormUtilsMixin from './FormUtilsMixin.vue';
import LoaderMixin from './LoaderMixin.vue';
import StatesMixin from './StatesMixin.vue';
import ToasterMixin from './ToasterMixin.vue';
import CommonsMixin from './CommonsMixin.vue';


/**
 * App Component that extends
 * some mixins to make Component inheritance easier
 * @see LoaderMixin
 * @see ToasterMixin
 */
@Component
export default class AppComponent extends Mixins(Mixins(LoaderMixin, ToasterMixin,
DataObserver, StatesMixin, FormUtilsMixin), Mixins(CommonsMixin)) { }

