const menstrualStatus = ['premenopause', 'perimenopause', 'postmenopause'];

export const menstrualStatusOptions: any = {
    premenopause: {
        text: 'Premenopause',
        value: menstrualStatus[0],
        description: '(before menopause, still experiencing regular periods)',
        validValues: ['premenopause', 'pre-menopause', 'pre menopause'],
    },
    perimenopause: {
        text: 'Perimenopause',
        value: menstrualStatus[1],
        description: '(changes in periods, but have not gone 12 months in a row without a period)',
        validValues: ['perimenopause', 'peri-menopause', 'peri menopause'],
    },
    postmenopause: {
        text: 'Postmenopause',
        value: menstrualStatus[2],
        description: '(after menopause)',
        validValues: ['postmenopause', 'post-menopause', 'post menopause'],
    },
};

export const timeOnBirthControlOptions: any = [
    'Less than a year',
    '1-3 years',
    '4-5 years',
    '6-10 years',
    '11-15 years',
    '16-20 years',
    'Over 20 years',
    'I have never used any form of contraception',
];

export const timeOnBirthControlOptionFallback = 'Other';

/**
 * Creates a list of select options (text - value)
 * for each of the timeOnBirthControlOptions values
 */
export const timeOnBirthControlOptionsSelect: any = (() => {
    const optionList: any = [];
    for (const option of timeOnBirthControlOptions) {
        optionList.push({
            text: option,
            value: option,
        });
    }
    optionList.push(timeOnBirthControlOptionFallback);
    return optionList;
})();

export const biomarkerSexOptions: any = [
    {
        text: 'Male',
        value: 'Male',
    },
    {
        text: 'Female',
        value: 'Female',
    },
    {
        text: 'All',
        value: 'All',
    },
];

export const menstrualStatusListOptions = (() => {
    const listOptions: any[] = [];
    for (const option of menstrualStatus) {
        listOptions.push({
            text: `${menstrualStatusOptions[option].text} ${menstrualStatusOptions[option].description}`,
            value: menstrualStatusOptions[option].value,

        });
    }
    return listOptions;
})();

export const outOfRangeTypes = [
    'positive',
    'negative',
    'below',
    'above',
    'present',
    'not present',
    'detected',
    'trace',
    'red',
    'yellow',
    'green',
    'clear',
    'a',
    'b',
    'c',
    'd',
];


export const STATES = [
    {
        text: 'Alaska',
        value: 'AK',
    },
    {
        text: 'Alabama',
        value: 'AL',
    },
    {
        text: 'Arizona',
        value: 'AZ',
    },
    {
        text: 'Arkansas',
        value: 'AR',
    },
    {
        text: 'California',
        value: 'CA',
    },
    {
        text: 'Colorado',
        value: 'CO',
    },
    {
        text: 'Connecticut',
        value: 'CT',
    },
    {
        text: 'Delaware',
        value: 'DE',
    },
    {
        text: 'District of Columbia',
        value: 'DC',
    },
    {
        text: 'Florida',
        value: 'FL',
    },
    {
        text: 'Georgia',
        value: 'GA',
    },
    {
        text: 'Hawaii',
        value: 'HI',
    },
    {
        text: 'Idaho',
        value: 'ID',
    },
    {
        text: 'Illinois',
        value: 'IL',
    },
    {
        text: 'Indiana',
        value: 'IN',
    },
    {
        text: 'Iowa',
        value: 'IA',
    },
    {
        text: 'Kansas',
        value: 'KS',
    },
    {
        text: 'Kentucky',
        value: 'KY',
    },
    {
        text: 'Louisiana',
        value: 'LA',
    },
    {
        text: 'Maine',
        value: 'ME',
    },
    {
        text: 'Maryland',
        value: 'MD',
    },
    {
        text: 'Massachusetts',
        value: 'MA',
    },
    {
        text: 'Michigan',
        value: 'MI',
    },
    {
        text: 'Minnesota',
        value: 'MN',
    },
    {
        text: 'Mississippi',
        value: 'MS',
    },
    {
        text: 'Missouri',
        value: 'MO',
    },
    {
        text: 'Montana',
        value: 'MT',
    },
    {
        text: 'Nebraska',
        value: 'NE',
    },
    {
        text: 'Nevada',
        value: 'NV',
    },
    {
        text: 'New Hampshire',
        value: 'NH',
    },
    {
        text: 'New Jersey',
        value: 'NJ',
    },
    {
        text: 'New Mexico',
        value: 'NM',
    },
    {
        text: 'New York',
        value: 'NY',
    },
    {
        text: 'North Carolina',
        value: 'NC',
    },
    {
        text: 'North Dakota',
        value: 'ND',
    },
    {
        text: 'Ohio',
        value: 'OH',
    },
    {
        text: 'Oklahoma',
        value: 'OK',
    },
    {
        text: 'Oregon',
        value: 'OR',
    },
    {
        text: 'Pennsylvania',
        value: 'PA',
    },
    {
        text: 'Puerto Rico',
        value: 'PR',
    },
    {
        text: 'Rhode Island',
        value: 'RI',
    },
    {
        text: 'South Carolina',
        value: 'SC',
    },
    {
        text: 'South Dakota',
        value: 'SD',
    },
    {
        text: 'Tennessee',
        value: 'TN',
    },
    {
        text: 'Texas',
        value: 'TX',
    },
    {
        text: 'Utah',
        value: 'UT',
    },
    {
        text: 'Vermont',
        value: 'VT',
    },
    {
        text: 'Virginia',
        value: 'VA',
    },
    {
        text: 'Washington',
        value: 'WA',
    },
    {
        text: 'West Virginia',
        value: 'WV',
    },
    {
        text: 'Wisconsin',
        value: 'WI',
    },
    {
        text: 'Wyoming',
        value: 'WY',
    },
];

export const listOfStatesShort = (() => {
    const listOptions: string[] = [];
    for (const option of STATES) {
        listOptions.push(option.value);
    }
    return listOptions;
})();


export const getStatetextByvalue = (statevalue: string) => {
    const state = STATES.find((s) => s.value === statevalue);
    return state ? state.text : '';
};



