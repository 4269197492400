
// mixins.js

import { dispatchAddNotification } from '@/store/main/actions';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ToasterMixin extends Vue {

    public toast(message: string, error: boolean = false) {
        dispatchAddNotification(this.$store, {
            message,
            color: error ? 'error' : 'success',
        });
    }
}
