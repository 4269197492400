import {
  BiomarkerCriticalPatternType,
  CAPTURE_NUMBERS_REGEX,
  CRITICAL_RANGE_PATTERN,
  CRITICAL_RANGE_PATTERN_EXCLUSIVE,
  CRITICAL_RANGE_PATTERN_INCLUSIVE,
  CriticalPatternOperator,
  EQUAL_REGEX,
  GREATER_OR_EQUAL_THAN_REGEX,
  GREATER_THAN_REGEX,
  LESS_OR_EQUAL_THAN_REGEX,
  LESS_THAN_REGEX,
  NUMERIC_EXCLUDE_PATTERNS,
  RANGE_REGEX,
} from '@/constants/biomarkerCriticalPatterns';

/**
 * Extract all the numbers from a string
 * using a regex that captures all the numbers
 * using business logic
 * @param stringToTest - the string to test
 * @returns - an array of numbers
 */
export const captureStringNumbers = (rawString: string) => {
  const stringToTest = rawString.toString(); // extract string from the possible observable
  const matches: IterableIterator<RegExpMatchArray> = stringToTest.matchAll(CAPTURE_NUMBERS_REGEX);
  const extractedNumbers: any[] = [];
  for (const match of matches) {
    const castedMatch: any = match;
    const keys = Object.keys(castedMatch.groups);
    for (const key of keys) {
      if (!!castedMatch.groups[key]) {
        extractedNumbers.push(castedMatch.groups[key]);
      }
    }
  }
  return extractedNumbers;
};

export const captureRegexMatches = (rawString: string, regex: RegExp) => {
  const stringToTest = rawString.toString(); // extract string from the possible observable
  const matches: IterableIterator<RegExpMatchArray> = stringToTest.matchAll(regex);
  const extractedMatches: any[] = [];
  for (const match of matches) {
    extractedMatches.push(match);
  }
  return extractedMatches;
};

/**
 * Calculates the critical range type based on a range string
 * @param rangeString - the range string
 * @returns - the critical range as a number
 */
export const getCriticalRangeType = (rangeString: string): number => {
  try {
    if (!rangeString || !rangeString.length) {
      // if the range is empty, return type equal
      return BiomarkerCriticalPatternType.ERROR;
    }
    let range = '';
    if (Array.isArray(rangeString)) {
      range = rangeString[0];
    } else {
      range = rangeString;
    }

    // check if the range does not contain any number
    const rangeArray = captureStringNumbers(range);
    const isTextRange = rangeArray.length === 0;

    if (isTextRange) {
      /*
          If the range is a text range, the out of range type
          will be equal to the result text.
          Example:
          If the result is "POSITIVE" and the range is "NEGATIVE",
          the out of range type will be "POSITIVE"
        */
      return BiomarkerCriticalPatternType.EQUAL;
    }

    const isRangeVariant = RANGE_REGEX.match.some((regex: RegExp) => regex.test(range));
    if (isRangeVariant) {
      // this is a range type, there should be two different numbers
      // extract the numbers in order to get max and min

      if (rangeArray && rangeArray.length === RANGE_REGEX.numberOfNumbers) {
        return BiomarkerCriticalPatternType.RANGE;
      }
    }
    const isLessOrEqualThanVariant = LESS_OR_EQUAL_THAN_REGEX.match.some((regex: RegExp) =>
      regex.test(range),
    );
    if (isLessOrEqualThanVariant) {
      return BiomarkerCriticalPatternType.LESS_OR_EQUAL_THAN;
    }

    const isGreaterOrEqualThanVariant = GREATER_OR_EQUAL_THAN_REGEX.match.some((regex: RegExp) =>
      regex.test(range),
    );
    if (isGreaterOrEqualThanVariant) {
      return BiomarkerCriticalPatternType.GREATER_OR_EQUAL_THAN;
    }

    const isLessThanVariant =
      LESS_THAN_REGEX.match.some((regex: RegExp) => regex.test(range)) &&
      !LESS_THAN_REGEX.exclude.every((regex: RegExp) => regex.test(range));
    if (isLessThanVariant) {
      return BiomarkerCriticalPatternType.LESS_THAN;
    }

    const isGreaterThanVariant =
      GREATER_THAN_REGEX.match.some((regex: RegExp) => regex.test(range)) &&
      !GREATER_THAN_REGEX.exclude.every((regex: RegExp) => regex.test(range));
    if (isGreaterThanVariant) {
      return BiomarkerCriticalPatternType.GREATER_THAN;
    }

    const isEqualVariant =
      EQUAL_REGEX.match.some((regex: RegExp) => regex.test(range)) &&
      !EQUAL_REGEX.exclude.every((regex: RegExp) => regex.test(range));
    if (isEqualVariant) {
      return BiomarkerCriticalPatternType.EQUAL;
    } else {
      throw new Error('no match');
    }
  } catch (e) {
    return BiomarkerCriticalPatternType.ERROR;
  }
};

/**
 * Calculates if a result is critical or not based on a result and a range string
 * both result and range strings can contain numbers and text, the algorithm will
 * extract the numbers and compare them to determine if the result is critical or not
 * based on the content of the range string.
 * Example:
 * result: "POSITIVE"; critical: "NEGATIVE" => NOT CRITICAL
 * result: "POSITIVE"; critical: "POSITIVE" => CRITICAL
 * result: "50"; critical: "0-100" => CRITICAL
 * result: "50"; critical: "0-49" => NOT CRITICAL
 * Special sintax
 * result: "100"; critical: "[0, 100]" => CRITICAL
 * result: "100"; critical: "[0,20][80,110] => CRITICAL
 */
export const calculateCriticalOutOfRange = (range: string, result: string) => {
  try {
    // check and maatch the range stirng and the result string with the different regex
    // to determine how to process the range string
    const criticalRanges = captureRegexMatches(range, CRITICAL_RANGE_PATTERN);
    const resultNumbers = captureStringNumbers(result);
    const rangeNumbers = captureStringNumbers(range);

    if (resultNumbers.length && rangeNumbers.length) {
      // the range is procesable as a range since it contains at least one number
      if (criticalRanges.length) {
        // in this case, the algorithm tries to calculate a range based on a sintax like this:
        // [0, 100] or (0, 100) (being the last one exclusive)
        // iterate over all the ranges in the critical range string and calculate if the result is critical or not
        let isCritical = false;
        for (const subRange of criticalRanges) {
          // check if the range is inclusive or exclusive
          if (isCritical) {
            // if the result is critical, no need to continue
            break;
          }
          const subRangeString = subRange[0];
          if (subRangeString.match(CRITICAL_RANGE_PATTERN)) {
            // split the string by the comma and extract the numbers of each part
            const rangeValues = subRangeString.split(',');
            const minPartNumbers = captureStringNumbers(rangeValues[0]);
            const maxPartNumbers = captureStringNumbers(rangeValues[1]);
            let min = NaN;
            let max = NaN;
            const resultNumber = parseFloat(resultNumbers[0]);
            // 0 for inclusive, 1 for exclusive
            let minPartOperator = CriticalPatternOperator.INCLUSIVE;
            let maxPartOperator = CriticalPatternOperator.INCLUSIVE;

            if (minPartNumbers.length) {
              min = parseFloat(minPartNumbers[0]);
            }
            if (maxPartNumbers.length) {
              max = parseFloat(maxPartNumbers[0]);
            }

            if (rangeValues[0]) {
              if (rangeValues[0].match(CRITICAL_RANGE_PATTERN_EXCLUSIVE) && !isNaN(min)) {
                minPartOperator = CriticalPatternOperator.EXCLUSIVE;
              }
            }

            if (rangeValues[1]) {
              if (rangeValues[1].match(CRITICAL_RANGE_PATTERN_EXCLUSIVE) && !isNaN(max)) {
                maxPartOperator = CriticalPatternOperator.EXCLUSIVE;
              }
            }

            if (!isNaN(min) && !isNaN(max)) {
              // calcualte if the result falls in the range
              isCritical =
                (minPartOperator === CriticalPatternOperator.EXCLUSIVE
                  ? resultNumber > min
                  : resultNumber >= min) &&
                (maxPartOperator === CriticalPatternOperator.EXCLUSIVE
                  ? resultNumber < max
                  : resultNumber <= max);
            } else if (!isNaN(min)) {
              // calcualte if the result is greater than the min
              isCritical =
                minPartOperator === CriticalPatternOperator.EXCLUSIVE
                  ? resultNumber > min
                  : resultNumber >= min;
            } else if (!isNaN(max)) {
              isCritical =
                maxPartOperator === CriticalPatternOperator.EXCLUSIVE
                  ? resultNumber < max
                  : resultNumber <= max;
            } else {
              // the range is not procesable, return false
              return false;
            }
          } else {
            // the range is not procesable, return false
            return false;
          }
        }
        return isCritical;
      } else {
        // range does not match the critical range pattern, try to calculate with OOR pattern
        const rangeType = getCriticalRangeType(range);
        try {
          const resultNumber = parseFloat(resultNumbers[0]);
          const rangeNumber = parseFloat(rangeNumbers[0]);
          switch (rangeType) {
            case BiomarkerCriticalPatternType.EQUAL:
              return resultNumber === rangeNumber;
            case BiomarkerCriticalPatternType.GREATER_THAN:
              return resultNumber > rangeNumber;
            case BiomarkerCriticalPatternType.GREATER_OR_EQUAL_THAN:
              return resultNumber >= rangeNumber;
            case BiomarkerCriticalPatternType.LESS_THAN:
              return resultNumber < rangeNumber;
            case BiomarkerCriticalPatternType.LESS_OR_EQUAL_THAN:
              return resultNumber <= rangeNumber;
            case BiomarkerCriticalPatternType.RANGE:
              return resultNumber >= rangeNumber && rangeNumber <= parseFloat(rangeNumbers[1]);
            default:
              return false;
          }
        } catch (e) {
          // failed while parsing, extracting or comparing the numbers
          return false;
        }
      }
    } else if (!resultNumbers.length && rangeNumbers.length > 0) {
      // if the result is a string and the range is a number
      // it can be asumed that the result is not critical. The most probable
      // scenario is that the result is something like "Not detected" and the
      // range is something like "0-100".
      return false;
    } else {
      // result is a number, range is a string or
      // result and range are strings, the result is critical if the result
      // is equal to the range
      return result.toString().toLowerCase() === range.toString().toLowerCase();
    }
  } catch (e) {
    /*failed while parsing, extracting or comparing the numbers */
  }

  return false;
};

/**
 * Parses the result string to a number if possible
 * based on the string content.
 * For example:
 * 1. "< 0.5" will return "0.4"
 * 2. "0.5" will return "0.5"
 * 3. "0.5-1.0" will return "0.5"
 * 4. ">= 0.5" will return "0.5"
 *
 * @param result  the result string
 */
export const parseResultOrRange = (result: string): string => {
  try {
    if (!result || result.length === 0) {
      return result; // early return
    }
    // check if the string contains at least one number and that the number
    // pattern is not in the excluded patterns
    const stringNumbers = captureStringNumbers(result);
    if (
      !!stringNumbers.length &&
      !NUMERIC_EXCLUDE_PATTERNS.every((pattern) => pattern.test(result))
    ) {
      // check if the string matches with the regex for range
      if (RANGE_REGEX.match.some((regex) => regex.test(result))) {
        // if this is a range of numbers, it means that
        // there is probably two numbers. we want to return the first number
        return stringNumbers[0];
      }
      // greater or equal case
      if (GREATER_OR_EQUAL_THAN_REGEX.match.some((regex) => regex.test(result))) {
        return stringNumbers[0];
      }
      // less or equal case, try to match the regex
      if (LESS_OR_EQUAL_THAN_REGEX.match.some((regex) => regex.test(result))) {
        return stringNumbers[0];
      }
      // less than case, try to match the regex
      if (LESS_THAN_REGEX.match.some((regex) => regex.test(result))) {
        const resultNumber = (parseFloat(stringNumbers[0]) - 0.1).toFixed(2).toString();
        return resultNumber;
      }
      // greater than case, try to match the regex
      if (GREATER_THAN_REGEX.match.some((regex) => regex.test(result))) {
        // in this case the result should be greater than the number
        // so we add 0.1 to make sure it is above the number
        const resultNumber = (parseFloat(stringNumbers[0]) + 0.1).toFixed(2).toString();
        return resultNumber;
      }
      // If we get here, it means that the result is a number but it didnt match any of the regex
      // we will just extract the number from the string and return it
      return stringNumbers[0];
    }
  } catch (error) {} // tslint:disable-line
  return result;
};
